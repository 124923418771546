export default {
  views: {
    list: {
      header: {
        title: "Companies",
        search_placeholder: "Search ...",
        add_button: "Add company"
      },
      table: {
        title: "Title",
        address: "Address",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active",
        domain: "Domain",
        country: "Country",
        tax_number: "Tax number"
      }
    },
    details: {
      header: {
        back_button: "Companies list",
        edit_button: "Edit company",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} company here"
        }
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified",
          domain: "Domain",
          tax_number: "Tax number",
          address: "Address",
          country: "Country"
        }
      },
      users: {
        title: "Company users",
        no_users: "Company doesn't have any users"
      }
    }
  },
  popup: {
    title: "New Company",
    fields: {
      title: {
        label: "Title"
      },
      address: {
        label: "Address"
      },
      postal: {
        label: "Postal"
      },
      country: {
        label: "Country"
      },
      tax_number: {
        label: "Tax number"
      },
      domain: {
        label: "Domain"
      },
      url: {
        label: "Ticketing URL"
      }
    },
    add_button: "Add company",
    update_button: "Update company"
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Company {title} created",
    updated: "Company {title} updated",
    deleted: "Company {title} was deleted",
    not_found: "Unable to find specified company"
  }
}
