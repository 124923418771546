import LaravelRepository from "@/providers/api/repositories/LaravelRepository"
import router from "@/providers/router"
export default class Auth extends LaravelRepository {
  route = "auth"
  namespace = "auth"

  state = {
    user: {},
    form: {}
  }

  actions = {
    login: async ({ commit }, params) => {
      const response = await this.request({ namespace: "login" }).post("/auth/login", params)
      if (response.isError) return response
      commit("SET_USER", response.data)
      return true
    },
    logout: async ({ commit }) => {
      const response = await this.request({ namespace: "logout" }).get("/auth/logout")
      if (response.isError) return response
      commit("CLEAR_USER")
      router.push("/login")
    },
    /**
     * Init request is make in src/providers/store.js
     * and sent payload to this action
     * This is a guard helper
     */
    init: async ({ commit }, payload) => {
      const response = await payload
      if (response.isError) return response
      commit("SET_USER", response.data)
    },
    /**
     * Get user payload
     */
    check: async ({ commit }) => {
      const response = await this.request({ namespace: "check" }).get("/auth/user")
      if (response.isError) return response
      commit("SET_USER", response.data)
      // commit("SET_EXPIRATION", promise.data)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    forgotPassword: async (ctx, params) => {
      return this.request({ namespace: "forgot-password" }).post("/forgot-password/create", params)
    },
    findPasswordToken: async (ctx, token) => {
      const response = await this.request({ namespace: "forgot-password/find" }).get("/forgot-password/find/" + token)
      return response
    },
    resetPassword: async (ctx, params) => {
      const response = await this.request({ namespace: "forgot-password/reset" }).post("/forgot-password/reset", params)
      return response
    },
    // update: async (params) => {
    //   //               .post
    //   return await this.request("/user/update", params)
    //   // dispatch("user")
    // },
    // changePassword: async (params) => {
    //   //               .post
    //   return await this.request("/user/change-password", params)
    // },
    //

    /**
     * Check if email already exists
     */
    checkEmail: async ({ commit }, params) => {
      const response = await this.request({ namespace: "checkEmail" }).post("/auth/check-email", params)
      if (response.isError) return response
      commit("SET_USER", response.data)
      // commit("SET_EXPIRATION", promise.data)
    },
    signup: async (ctx, params) => {
      const response = await this.request({ namespace: "signup" }).post("/auth/signup", params)
      return response
    },
    activateAccount: async ({ commit }, token) => {
      const response = await this.request({ namespace: "activate-account" }).post("/auth/signup/activate/" + token)
      if (response.isError) return response
      commit("SET_USER", response.data)
      return response
    },
    invitationTicket: async (ctx, ticket) => {
      const response = await this.request({ namespace: "invitationTicket" }).post("/auth/signup/invite/" + ticket)
      return response
    }
  }
  getters = {
    isAuthenticated: (state) => {
      return Object.keys(state.user).length !== 0
    }
  }
  mutations = {
    SET_USER: (state, payload) => {
      state.user = payload
    },
    CLEAR_USER: (state) => {
      state.user = {}
    }
  }
}
