export default [
  {
    path: "/",
    redirect: "/dashboard" // default route
  },
  {
    path: "*",
    redirect: "/not-found" // 404
  }
]
