export default {
  views: {
    list: {
      key: "Key",
      data: "Value",
      updated_at: "Updated at",
      created_at: "Created at",
      title: "Title",
      category_id: "Category",
      color: "Color",
      icon: "Icon",
      desc: "Description",
      groupId: "Group id",
      groupName: "Group name",

      save: "Save",
      remove: "Remove",
      remove_category_msg: "Codelist {title} was deleted.",
      remove_msg: "Item was deleted.",
      remove_error: "Error has occurred while deleting an item.",
      updated_msg: "Entry was updated.",
      update_error: "Error has occurred while updating an item.",
      store_error: "Error has occurred while storing an item."
    },
    header: {
      title: "Codelists",
      add: "Add item"
    },
    category: {
      title: "Categories"
    }
  }
}
