import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { title: "Dashboard" },
    component: () => import(/* webpackChunkName: "Companies List" */ "@/Modules/Dashboard/Views/Default/Dashboard"),
    beforeEnter: ifAuthenticated
  }
]
