export default {
  views: {
    list: {
      header: {
        title: "Document Templates",
        search_placeholder: "Search ...",
        add_button: "Add document template"
      },
      table: {
        title: "Title",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active"
      }
    },
    category: {
      title: "Categories",
      edit: "Edit category",
      new: "Add category",
      save: "Save",
      delete: "Delete",
      cancel: "Cancel",
      edit2: "Edit",
      fields: {
        title: "Title"
      }
    },
    details: {
      header: {
        back_button: "Document Templates list",
        edit_button: "Edit document template",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} document template here"
        }
      },
      upload: {
        file: "Upload file"
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        }
      }
    },
    edit: {
      header: {
        remove_button: "Delete",
        back_button: "Back to templates list"
      },
      tabs: {
        properties: "Properties",
        scope: "Scope"
      },
      upload: {
        file: "Upload file"
      },
      footer: {
        save_button: "Save"
      },
      properties: {
        title: "Title",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        file: "File",
        category: "Category",
        view: "View",
        allowed_export_formats: "Allowed export formats"
      },
      scope: {
        table: {
          model: "Model",
          feature: "Feature",
          response_key: "Response key"
        },
        add_model: "Add model",
        add_relation: "Add relation"
      }
    }
  },
  popup: {
    title: "New Document Template",
    fields: {
      title: {
        label: "Title"
      }
    },
    add_button: "Add document template",
    update_button: "Update document template"
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Document template {title} created",
    updated: "Document template {title} updated",
    deleted: "Document template {title} was deleted",
    not_found: "Unable to find specified document template"
  }
}
