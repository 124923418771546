import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/entities",
    name: "Entities",
    meta: { title: "Entities" },
    component: () => import(/* webpackChunkName: "Entities List" */ "@/Modules/Entity/Views/List/List"),
    children: [
      {
        path: "new",
        name: "New Entity",
        meta: { title: "New Entity" }
      },
      {
        path: "edit/:id",
        name: "Edit Entity (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/entities/details/:id",
    name: "Entity Details",
    component: () => import(/* webpackChunkName: "Entity Details" */ "@/Modules/Entity/Views/Details/Details"),
    children: [
      {
        path: "edit",
        name: "Edit Entity (Details)"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
