import { ifNotAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"
export default [
  {
    path: "/login",
    name: "Login",
    meta: { title: "Log In" },
    component: () => import(/* webpackChunkName: "Auth" */ "@/Modules/Auth/Auth/Views/Login.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/login/forgot-password",
    name: "Forgot password",
    meta: { title: "Reset Password" },
    component: () => import(/* webpackChunkName: "Auth" */ "@/Modules/Auth/Auth/Views/ForgotPassword.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/login/reset-password",
    name: "Reset password",
    meta: { title: "New Password" },
    component: () => import(/* webpackChunkName: "Auth" */ "@/Modules/Auth/Auth/Views/ResetPassword.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/registration/invite/:token",
    name: "Invitation ticket",
    meta: { title: "Registration" },
    component: () => import(/* webpackChunkName: "AuthRegistration" */ "@/Modules/Auth/Auth/Views/Registration/Profile.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/registration",
    name: "Registration",
    meta: { title: "Registration" },
    component: () => import(/* webpackChunkName: "AuthRegistration" */ "@/Modules/Auth/Auth/Views/Registration/Profile.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/registration/set-password",
    name: "Set password",
    meta: { title: "Set Password" },
    component: () => import(/* webpackChunkName: "AuthRegistration" */ "@/Modules/Auth/Auth/Views/Registration/SetPassword.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/registration/activate",
    name: "Activate account",
    meta: { title: "Activate account" },
    component: () => import(/* webpackChunkName: "AuthRegistration" */ "@/Modules/Auth/Auth/Views/Registration/Activate.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/registration/activate/:token",
    name: "Activating account",
    meta: { title: "Activating your account" },
    component: () => import(/* webpackChunkName: "AuthRegistration" */ "@/Modules/Auth/Auth/Views/Registration/Activated.vue"),
    beforeEnter: ifNotAuthenticated
  }
]
