export default {
  views: {
    form: {
      slides: {
        navigation: {
          back: "Nazaj",
          conclude: "Dodaj konfiguracijo na seznam",
          continue: "Nadaljuj"
        },
        content: {
          heading: {
            page1: {
              1: "Sestavite vaše VPS gostovanje!"
            }
          },
          subtext: {
            1: "Za točno ponudbo nas kontaktirajte na"
          },
          fields: {
            vcpu: "vCPU",
            ram: "SPOMIN",
            ssd: "SSD DISK",
            sas: "SAS DISK",
            datacenter: "PODATKOVNI CENTER",
            os: "OS",
            ip: "Št. statičnih WAN IP",
            ipbgp: "Št. statičnih WAN IP - BGP",
            uservpn: "Št. User VPN",
            backup: "Backup",
            remote_desktop: "Remote desktop",
            sql_server: "SQL Server",
            remote_desktop_users: "Remote desktop - št. uporabnikov",
            sql_server_per_user: "SQL Server - št. uporabnikov",
            sql_server_per_core: "SQL Server - št. licenc (opomba: 2core licenca; min. 4core = 2 licenci)"
          },
          completed: {
            p1: "Konfiguracija je bila dodana na seznam.",
            p2: "Za dodajanje dodatne konfiguracije kliknite na spodnji gumb.",
            new_offer: "Nova konfiguracija"
          }
        }
      },
      title_config: "Vloga (ime konfiguracije)",
      price_config: "Cena konfiguracije",
      price_monthly: "€ / mesečno",
      configurations_list: "Seznam konfiguracij",
      offer: "Ponudba",
      contract: "Pogodba",
      price_total: "Skupaj: ",
      recipient_popup: {
        title: "Vpišite vaše podatke",
        company: {
          label: "Podjetje"
        },
        tax_number: {
          label: "Davčna številka"
        },
        contact: {
          label: "Kontaktna oseba"
        },
        email: {
          label: "Elektronski naslov"
        },
        phone: {
          label: "Telefonska številka"
        },
        close: "Prekliči",
        confirm_offer: "Pridobi ponudbo",
        confirm_contract: "Pridobi pogodobo",
        notify_success: "Zahtevana datoteka bo poslana na elektronski naslov.",
        notify_error: "Prišlo je do napake."
      }
    }
  }
}
