<template>
    <div id="app" class="uik--restyle">
        <Authentication @ready="loaded.Authentication = true"/>

        <Navigation v-if="showNavigation && $api.Auth.isAuthenticated"/>

        <transition :name="transitionName" mode="out-in">
            <router-view v-if="showApp"/>
        </transition>

        <CodelistData
            v-if="$api.Auth.isAuthenticated"
            :categories="$options.getCodelistCategories"
            @ready="loaded.CodelistData = true"
        />
        <UpdateAvailable/>

        <Loading :state="loaded.Authentication"/>
    </div>
</template>

<script>
import Authentication from "./Modules/Auth/Components/Authentication"
import UpdateAvailable from "./Components/Support/UpdateAvailable"
import Navigation from "@/Components/Navigation"
import Loading from "@/Components/Loading"
import CodelistData from "@/Modules/Codelist/Components/GetData"
import Auth from "@/Modules/Auth/Auth/Resources/Auth"

export default {
  components: { Authentication, Navigation, CodelistData, Loading, UpdateAvailable },
  resources: { Auth },
  getCodelistCategories: ["document-template-views", "document-template-categories"],
  data () {
    return {
      transitionName: "",
      disableNavigationInViews: ["/login", "/not-found", "/offers"],
      loaded: {
        CodelistData: false,
        Authentication: false
      }
    }
  },
  computed: {
    showNavigation () {
      let show = true
      const route = this.$route.path
      this.disableNavigationInViews.forEach(path => {
        if (route.startsWith(path)) show = false
      })
      return show
    },
    showApp () {
      if (this.loaded.Authentication && !this.$api.Auth.isAuthenticated) return true
      return this.loaded.CodelistData
    }
  },
  watch: {
    "$route" (to, from) {
      // Add fade only transition routes to this array
      const staticViews = ["login", "settings"]
      if (staticViews.includes(this.$route.name)) {
        this.transitionName = "route-fade"
        return
      }
      if (!to.path.split("/").includes(from.path.split("/")[1])) {
        this.transitionName = "route-fade"
        return
      }
      const toDepth = to.path.split("/").length
      const fromDepth = from.path.split("/").length
      if (toDepth < fromDepth) {
        this.transitionName = "slide-right"
      } else if (toDepth > fromDepth) {
        this.transitionName = "slide-left"
      } else {
        this.transitionName = "route-fade"
      }
    }
  }
}
</script>

<style lang="scss">
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.updateAvailable {
  width: 300px;
  position: fixed;
  top: 10px;
  left: 50%;
  margin-left: -150px;
}
@import '@/Styles/index.scss';
</style>
